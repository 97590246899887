export default function CancelFilled({ ...props }) {
    return (
        <svg
            t="1658464134071"
            className="icon"
            {...props}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2261"
            width="128"
            height="128"
        >
            <path
                d="M509.92 843.84C325.504 843.84 176 694.32 176 509.92 176 325.504 325.504 176 509.92 176c184.416 0 333.92 149.504 333.92 333.92 0 184.416-149.504 333.92-333.92 333.92z m5.552-367.872l-74.032-74.032a16 16 0 0 0-22.624 0l-11.312 11.312a16 16 0 0 0 0 22.64l74.032 74.032-74.032 74.032a16 16 0 0 0 0 22.624l11.312 11.312a16 16 0 0 0 22.624 0l74.032-74.032 74.032 74.032a16 16 0 0 0 22.64 0l11.312-11.312a16 16 0 0 0 0-22.624l-74.032-74.032 74.032-74.032a16 16 0 0 0 0-22.64l-11.312-11.312a16 16 0 0 0-22.64 0l-74.032 74.032z"
                p-id="2262"
            />
        </svg>
    );
}
