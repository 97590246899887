import type { SVGProps } from 'react';

export default function Email(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M512 1024C229.216 1024 0 794.784 0 512S229.216 0 512 0s512 229.216 512 512-229.216 512-512 512z m113.792-529.696l142.112 197.024L768 408.96l-142.208 85.344zM258.016 352L512 522.752 766.624 352H258.016z m-1.92 340.16l142.112-197.856L256 408.96l0.096 283.2z m256.16-112.448l-85.6-56.96-113.76 170.688h398.208l-113.76-170.688L512 579.648l0.416 0.16-0.096-0.032-0.064 0.032v-0.096z"
                fill="#8B5CF6"
            />
        </svg>
    );
}
