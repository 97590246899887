import { type SVGProps } from 'react';

export default function EmailOutlineColored(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.541 10.9997L8.66602 7.49967M5.33268 7.49967L1.4577 10.9997M1.16602 4.58301L5.92888 7.91702C6.31457 8.18699 6.50741 8.32198 6.71717 8.37427C6.90245 8.42046 7.09624 8.42046 7.28153 8.37427C7.49129 8.32198 7.68413 8.187 8.06981 7.91702L12.8327 4.58301M3.96602 12.1663H10.0327C11.0128 12.1663 11.5028 12.1663 11.8772 11.9756C12.2064 11.8078 12.4742 11.5401 12.6419 11.2108C12.8327 10.8365 12.8327 10.3464 12.8327 9.36634V5.63301C12.8327 4.65292 12.8327 4.16287 12.6419 3.78852C12.4742 3.45924 12.2064 3.19152 11.8772 3.02375C11.5028 2.83301 11.0128 2.83301 10.0327 2.83301H3.96602C2.98592 2.83301 2.49588 2.83301 2.12153 3.02375C1.79225 3.19152 1.52453 3.45924 1.35675 3.78852C1.16602 4.16287 1.16602 4.65292 1.16602 5.63301V9.36634C1.16602 10.3464 1.16602 10.8365 1.35675 11.2108C1.52453 11.5401 1.79225 11.8078 2.12153 11.9756C2.49588 12.1663 2.98592 12.1663 3.96602 12.1663Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
