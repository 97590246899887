import type { SVGProps } from 'react';

export default function BoxFilled({ ...props }: SVGProps<SVGSVGElement>) {
    return (
        <svg width={65} height={65} viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    opacity={0.35}
                    d="M41.2358 46.1081H22.6643C20.0999 46.1081 18.0215 44.0297 18.0215 41.4653V22.8938C18.0215 20.3294 20.0999 18.251 22.6643 18.251H41.2358C43.8002 18.251 45.8786 20.3294 45.8786 22.8938V41.4653C45.8786 44.0297 43.8002 46.1081 41.2358 46.1081Z"
                    fill="#8B5CF6"
                />
                <path
                    d="M45.8786 36.8225V41.4654C45.8786 44.0298 43.8002 46.1082 41.2358 46.1082H22.6643C20.0999 46.1082 18.0215 44.0298 18.0215 41.4654V36.8225H24.7412C25.9809 36.8225 27.1896 37.4895 27.6709 38.6317C28.3751 40.2985 30.0233 41.4654 31.9501 41.4654C33.8768 41.4654 35.5251 40.2985 36.2292 38.6317C36.7105 37.4895 37.9177 36.8225 39.1589 36.8225H45.8786Z"
                    fill="#8B5CF6"
                />
            </g>
        </svg>
    );
}
