import type { SVGProps } from 'react';

export default function MicroInfluencer(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none" {...props}>
            <path
                d="M1.71777 19.1844H9.21777M7.94731 6.35924C8.52643 6.35924 9.04437 6.35924 9.61536 6.35924C13.2973 6.35924 16.282 9.34401 16.282 13.0259C16.282 15.8237 14.5776 17.7108 12.1344 18.6998M6.1441 3.94425C6.60434 2.4112 6.8451 1.6025 6.8451 1.6025M6.3333 4.00325L7.02135 1.64222M6.21917 0.816409C6.97582 1.02923 7.95523 1.31406 7.95523 1.31406M4.21777 11.6844H6.71777C7.10499 11.6844 7.29859 11.6844 7.45959 11.7164C8.12074 11.8479 8.63757 12.3647 8.76908 13.0259C8.80111 13.1869 8.80111 13.3805 8.80111 13.7677C8.80111 14.1549 8.83313 14.0234 8.80111 14.1844C8.6696 14.8456 8.15277 15.3624 7.49161 15.4939C7.33062 15.526 7.13701 15.526 6.7498 15.526H4.2498C3.86258 15.526 3.66898 15.526 3.50798 15.4939C2.84683 15.3624 2.33 14.8456 2.19849 14.1844C2.16646 14.0234 2.13444 14.1549 2.13444 13.7677C2.13444 13.3805 2.13444 13.1869 2.16646 13.0259C2.29798 12.3647 2.8148 11.8479 3.47596 11.7164C3.63695 11.6844 3.83056 11.6844 4.21777 11.6844ZM2.96777 6.77595V11.7164L5.46777 11.6844H7.96777V6.77595C7.96777 5.16512 7.0786 3.85929 5.46777 3.85929C3.85694 3.85929 2.96777 5.16512 2.96777 6.77595Z"
                stroke="#7C3AED"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
