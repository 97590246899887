import type { SVGProps } from 'react';

export default function BoostbotSelected(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M23.9548 14.3966C23.9548 17.8233 21.5783 19.9991 17.2221 19.9991H16.2612C16.4022 19.8965 16.5396 19.7912 16.6707 19.6796C18.1677 18.4006 18.9595 16.5742 18.9595 14.3966C18.9595 12.7563 18.4587 11.3557 17.4707 10.2347C17.2746 10.0113 17.0585 9.80166 16.8253 9.6056C16.8461 9.58381 16.866 9.56293 16.8859 9.54205C17.8784 8.48727 18.381 7.12296 18.381 5.48632C18.381 4.45877 18.1903 3.49476 17.8142 2.62153C17.4355 1.74194 16.8777 0.974002 16.1573 0.338591C16.0361 0.231479 15.9096 0.130719 15.7803 0.0326843H17.1923C20.9401 0.0326843 23.3772 2.17856 23.3772 5.48632C23.3772 6.73808 23.0111 7.72115 22.3105 8.4664C21.6099 9.21164 20.9473 9.61921 20.1247 9.70817C20.1247 9.70817 20.2368 9.70817 20.3064 9.72904C22.2526 10.3045 23.9557 11.7124 23.9557 14.3975L23.9548 14.3966ZM13.5042 9.70817C14.3268 9.61921 14.9894 9.21164 15.6899 8.4664C16.3905 7.72115 16.7566 6.73808 16.7566 5.48632C16.7566 2.17856 14.3195 0.0326843 10.5717 0.0326843H7.60135V9.70817H13.5042ZM0 0.0326843V9.70817L7.60135 0.0326843H0ZM0 9.70817V20H5.3957V9.70817H0ZM13.6859 9.72904C13.6163 9.70817 13.5042 9.70817 13.5042 9.70817L5.3957 20H10.6025C14.9595 20 17.3351 17.8242 17.3351 14.3975C17.3351 11.7124 15.6321 10.3045 13.6859 9.72904Z"
                fill="url(#paint0_linear_1762_146948)"
            />
            <g style={{ mixBlendMode: 'screen' }}>
                <path
                    d="M13.5042 9.70817C14.3268 9.61922 14.9894 9.21165 15.69 8.4664C16.3905 7.72116 16.7566 6.73808 16.7566 5.48632C16.7566 2.17856 14.3196 0.0326881 10.5718 0.0326881H7.60138V9.70817H13.5042Z"
                    fill="url(#paint1_linear_1762_146948)"
                />
            </g>
            <g style={{ mixBlendMode: 'hard-light' }}>
                <path
                    d="M20.3516 9.69636C20.282 9.67548 20.1699 9.67548 20.1699 9.67548C20.9925 9.58652 21.6551 9.17895 22.3557 8.43371C23.0562 7.68846 23.4223 6.70539 23.4223 5.45363C23.4223 2.12619 20.9853 0 17.2375 0H16.0262C19.774 0 22.211 2.14587 22.211 5.45363C22.211 6.70539 21.8449 7.68846 21.1444 8.43371C20.4438 9.17895 19.7812 9.58652 18.9586 9.67548C18.9586 9.67548 19.0707 9.67548 19.1403 9.69636C21.0865 10.2719 22.7896 11.6797 22.7896 14.3648C22.7896 17.7243 20.5053 19.8793 16.3118 19.9637C16.31 19.9646 16.3082 19.9664 16.3064 19.9673H17.2673C21.6244 19.9673 24 17.7915 24 14.3648C24 11.6797 22.2978 10.2719 20.3516 9.69636Z"
                    fill="url(#paint2_linear_1762_146948)"
                />
            </g>
            <g style={{ mixBlendMode: 'hard-light' }}>
                <path
                    d="M13.6805 9.72814C13.6109 9.70726 13.4988 9.70726 13.4988 9.70726C14.3214 9.61831 14.984 9.21074 15.6845 8.46549C16.3851 7.72024 16.7512 6.73717 16.7512 5.48541C16.7512 2.17765 14.3141 0.0317802 10.5663 0.0317802H9.35504C13.1028 0.0317802 15.5399 2.17765 15.5399 5.48541C15.5399 6.73717 15.1738 7.72024 14.4732 8.46549C13.7727 9.21074 13.1101 9.61831 12.2875 9.70726H13.4988L13.261 10.0168C13.261 10.0168 13.2737 10.0222 13.28 10.025C14.8655 10.7294 16.1193 12.0928 16.1193 14.3966C16.1193 17.7561 13.835 19.911 9.64159 19.9955C9.63979 19.9964 9.63798 19.9982 9.63617 19.9991H10.5971C14.9541 19.9991 17.3297 17.8233 17.3297 14.3966C17.3297 11.7115 15.6267 10.3036 13.6805 9.72814Z"
                    fill="url(#paint3_linear_1762_146948)"
                />
            </g>
            <path d="M0 9.70817L7.60135 0.0317802H6.88542L0 8.7015V9.70817Z" fill="url(#paint4_linear_1762_146948)" />
            <path d="M5.39479 9.70817H4.77106V19.9991H5.39479V9.70817Z" fill="url(#paint5_linear_1762_146948)" />
            <defs>
                <linearGradient
                    id="paint0_linear_1762_146948"
                    x1="0.0849715"
                    y1="-0.0535498"
                    x2="20.6303"
                    y2="20.4064"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6969" />
                    <stop offset="0.37" stopColor="#6C00FA" />
                    <stop offset="0.82" stopColor="#0049FF" />
                    <stop offset="1" stopColor="#47DEEA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1762_146948"
                    x1="6.69291"
                    y1="0.944048"
                    x2="15.034"
                    y2="9.25132"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6969" />
                    <stop offset="0.37" stopColor="#6C00FA" />
                    <stop offset="0.82" stopColor="#0049FF" />
                    <stop offset="1" stopColor="#47DEEA" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1762_146948"
                    x1="12.1862"
                    y1="3.85694"
                    x2="24.6483"
                    y2="16.2672"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6969" />
                    <stop offset="0.37" stopColor="#6C00FA" />
                    <stop offset="0.82" stopColor="#0049FF" />
                    <stop offset="1" stopColor="#47DEEA" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1762_146948"
                    x1="5.51505"
                    y1="3.88872"
                    x2="17.9771"
                    y2="16.299"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6969" />
                    <stop offset="0.37" stopColor="#6C00FA" />
                    <stop offset="0.82" stopColor="#0049FF" />
                    <stop offset="1" stopColor="#47DEEA" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1762_146948"
                    x1="7.60135"
                    y1="4.86998"
                    x2="-0.0569492"
                    y2="4.86998"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6969" />
                    <stop offset="0.37" stopColor="#6C00FA" />
                    <stop offset="1" stopColor="#29A6DD" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_1762_146948"
                    x1="2.36563"
                    y1="12.1245"
                    x2="7.82375"
                    y2="17.56"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6C00FA" />
                    <stop offset="0.46" stopColor="#0049FF" />
                    <stop offset="1" stopColor="#47DEEA" />
                </linearGradient>
            </defs>
        </svg>
    );
}
