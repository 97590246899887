import type { SVGProps } from 'react';

export default function ThunderMail(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M20.0476 9.52632V5.54737C20.0476 4.84895 20.0476 4.30375 20.0213 3.86042M10.5238 16.1579H5.57143C3.97128 16.1579 3.1712 16.1579 2.56003 15.8481C2.02242 15.5756 1.58533 15.1409 1.31141 14.6061C1 13.9981 1 13.2023 1 11.6105V5.54737C1 4.84895 1 4.30375 1.02631 3.86042M20.0213 3.86042C19.9877 3.29339 19.911 2.89301 19.7362 2.55182C19.4623 2.01704 19.0252 1.58225 18.4876 1.30977C17.8764 1 17.0763 1 15.4762 1H5.57143C3.97128 1 3.1712 1 2.56003 1.30977C2.02242 1.58225 1.58533 2.01704 1.31141 2.55182C1.13664 2.89301 1.05996 3.29339 1.02631 3.86042M20.0213 3.86042L12.2715 9.25674C11.6418 9.6952 11.327 9.91443 10.9845 9.99934C10.8726 10.0271 10.7175 10.0343 10.5664 10.0324C10.4786 10.0313 10.4347 10.0307 10.2905 10.0077C10.2081 9.99454 9.99344 9.93845 9.91514 9.90963C9.77814 9.8592 9.72341 9.82942 9.61396 9.76984C9.3765 9.64061 9.07323 9.46362 8.77611 9.25674L7.80272 8.57895M20.0213 3.86042L20.0476 3.84211M1.02631 3.86042L1 3.84211M1.02631 3.86042L7.80272 8.57895M7.80272 8.57895L1.47622 14.2632M17.1905 9.52632V13.0789H20.1702C20.5571 13.0789 20.7505 13.0789 20.8534 13.1621C20.943 13.2345 20.9967 13.3447 20.9999 13.4625C21.0036 13.5979 20.8892 13.7599 20.6605 14.084L17.1905 19V15.4474H14.2107C13.8239 15.4474 13.6305 15.4474 13.5276 15.3642C13.438 15.2918 13.3843 15.1816 13.381 15.0638C13.3773 14.9284 13.4917 14.7664 13.7205 14.4423L15.4555 11.9843L17.1905 9.52632Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
