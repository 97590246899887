import type { SVGProps } from 'react';

export default function Send(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.74987 8.00031H3.16653M3.09599 8.24323L1.15022 14.0555C0.997359 14.5121 0.920928 14.7404 0.975779 14.881C1.02341 15.0031 1.12571 15.0957 1.25195 15.1309C1.39731 15.1715 1.61687 15.0727 2.05598 14.8751L15.9822 8.60826C16.4108 8.41539 16.6251 8.31895 16.6913 8.18499C16.7489 8.0686 16.7489 7.93203 16.6913 7.81564C16.6251 7.68168 16.4108 7.58524 15.9822 7.39237L2.05112 1.12342C1.61334 0.926414 1.39444 0.827912 1.24922 0.868319C1.12311 0.90341 1.02082 0.995732 0.973018 1.1176C0.917981 1.25793 0.993595 1.48574 1.14482 1.94137L3.09653 7.82159C3.1225 7.89984 3.13549 7.93897 3.14062 7.97899C3.14517 8.0145 3.14512 8.05045 3.14048 8.08594C3.13525 8.12595 3.12216 8.16504 3.09599 8.24323Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
