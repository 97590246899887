export default function ImportantTag({ ...props }) {
    return (
        <svg
            t="1658414568556"
            className="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2370"
            {...props}
        >
            <path
                d="M518.142555 69.374735C274.608327 69.374735 76.60127 267.381793 76.60127 510.91602s198.007057 441.541284 441.541285 441.541284 441.541284-198.007057 441.541284-441.541284S760.954128 69.374735 518.142555 69.374735z m-27.460833 224.745237c0-15.175723 12.285109-26.738179 26.738179-26.738179 15.175723 0 26.738179 12.285109 26.738179 26.738179v353.377558c0 15.175723-12.285109 26.738179-26.738179 26.738179-15.175723 0-26.738179-12.285109-26.738179-26.738179V294.119972z m27.460833 431.424135c16.62103 0 29.628793 13.007763 29.628793 29.628793s-13.007763 29.628793-29.628793 29.628794-29.628793-13.007763-29.628794-29.628794c-0.722653-15.898377 13.007763-29.628793 29.628794-29.628793z"
                p-id="2371"
            />
        </svg>
    );
}
