import type { SVGProps } from 'react';

export default function BoostbotDefault(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 24 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M23.8015 14.3965C23.8015 17.8232 21.4402 19.9991 17.1119 19.9991H16.1572C16.2973 19.8965 16.4338 19.7912 16.5641 19.6796C18.0514 18.4005 18.8382 16.5742 18.8382 14.3965C18.8382 12.7562 18.3406 11.3555 17.3589 10.2345C17.164 10.0111 16.9494 9.80146 16.7176 9.60538C16.7383 9.5836 16.7581 9.56272 16.7778 9.54184C17.764 8.48704 18.2634 7.12269 18.2634 5.48602C18.2634 4.45845 18.0739 3.49443 17.7002 2.62117C17.3239 1.74157 16.7697 0.973613 16.0539 0.338189C15.9335 0.231075 15.8078 0.130313 15.6794 0.0322762H17.0823C20.8061 0.0322762 23.2276 2.17819 23.2276 5.48602C23.2276 6.73781 22.8638 7.7209 22.1677 8.46616C21.4717 9.21142 20.8133 9.619 19.996 9.70796C19.996 9.70796 20.1073 9.70796 20.1765 9.72883C22.1102 10.3043 23.8024 11.7123 23.8024 14.3974L23.8015 14.3965ZM13.4178 9.70796C14.2351 9.619 14.8935 9.21142 15.5895 8.46616C16.2856 7.7209 16.6494 6.73781 16.6494 5.48602C16.6494 2.17819 14.2279 0.0322762 10.5041 0.0322762H7.55271V9.70796H13.4178ZM0 0.0322762V9.70796L7.55271 0.0322762H0ZM0 9.70796V20H5.36118V9.70796H0ZM13.5983 9.72883C13.5291 9.70796 13.4178 9.70796 13.4178 9.70796L5.36118 20H10.5346C14.8638 20 17.2242 17.8241 17.2242 14.3974C17.2242 11.7123 15.5321 10.3043 13.5983 9.72883Z"
                fill="currentColor"
            />
            <path
                d="M13.4178 9.70796C14.2351 9.619 14.8935 9.21142 15.5895 8.46616C16.2856 7.7209 16.6494 6.73781 16.6494 5.48602C16.6494 2.17819 14.2279 0.0322762 10.5041 0.0322762H7.55271V9.70796H13.4178Z"
                fill="currentCOlor"
            />
        </svg>
    );
}
