import type { SVGProps } from 'react';

export default function Trashcan(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            className="icon"
            {...props}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3145"
            width="128"
            height="128"
        >
            <path
                d="M402.286446 786.285714v-402.285714c0-10.276571-8.009143-18.285714-18.285715-18.285714h-36.571428c-10.276571 0-18.285714 8.009143-18.285714 18.285714v402.285714c0 10.276571 8.009143 18.285714 18.285714 18.285715h36.571428c10.276571 0 18.285714-8.009143 18.285715-18.285715z m146.285714 0v-402.285714c0-10.276571-8.009143-18.285714-18.285714-18.285714h-36.571429c-10.276571 0-18.285714 8.009143-18.285714 18.285714v402.285714c0 10.276571 8.009143 18.285714 18.285714 18.285715h36.571429c10.276571 0 18.285714-8.009143 18.285714-18.285715z m146.285714 0v-402.285714c0-10.276571-8.009143-18.285714-18.285714-18.285714h-36.571429c-10.276571 0-18.285714 8.009143-18.285714 18.285714v402.285714c0 10.276571 8.009143 18.285714 18.285714 18.285715h36.571429c10.276571 0 18.285714-8.009143 18.285714-18.285715zM384.000731 219.428571h256l-27.428571-66.852571A21.942857 21.942857 0 0 0 602.84416 146.285714H421.705874a19.236571 19.236571 0 0 0-9.728 6.290286z m530.285715 18.285715v36.571428c0 10.276571-8.009143 18.285714-18.285715 18.285715H841.143589v541.696c0 62.866286-41.142857 116.553143-91.428572 116.553142h-475.428571c-50.285714 0-91.428571-51.419429-91.428572-114.285714V292.534857H128.000731a18.066286 18.066286 0 0 1-18.285714-18.285714v-36.571429c0-10.276571 8.009143-18.285714 18.285714-18.285714h176.566858L344.576731 123.977143c11.446857-28.013714 45.714286-50.870857 75.995429-50.870857h182.857143c30.281143 0 64.585143 22.857143 75.995428 50.870857l40.009143 95.414857H896.000731c10.276571 0 18.285714 8.009143 18.285715 18.285714z"
                fill=""
                p-id="3146"
            />
        </svg>
    );
}
