export default function TiktokNoBg() {
    return (
        <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.01411 6.20186C9.6143 6.63243 10.3496 6.88577 11.1437 6.88577V5.3522C10.9934 5.35223 10.8435 5.3365 10.6965 5.30524V6.51238C9.90241 6.51238 9.16724 6.25904 8.56689 5.8285V8.95808C8.56689 10.5236 7.30225 11.7927 5.74234 11.7927C5.1603 11.7927 4.61932 11.6161 4.16992 11.3133C4.68283 11.8396 5.39812 12.1661 6.18947 12.1661C7.74948 12.1661 9.01418 10.897 9.01418 9.33137V6.20186H9.01411V6.20186ZM9.56581 4.65468C9.25908 4.31838 9.05769 3.88378 9.01411 3.4033V3.20605H8.5903C8.69698 3.81673 9.06085 4.33846 9.56581 4.65468ZM5.15657 10.1119C4.98519 9.88638 4.89258 9.61053 4.893 9.32689C4.893 8.61086 5.47143 8.03028 6.18507 8.03028C6.31806 8.03025 6.45026 8.05069 6.57701 8.09105V6.5232C6.42889 6.50283 6.2794 6.49418 6.12997 6.49735V7.71769C6.00313 7.67733 5.87087 7.65683 5.73784 7.65696C5.02421 7.65696 4.4458 8.23746 4.4458 8.95359C4.4458 9.45995 4.73493 9.89833 5.15657 10.1119Z"
                fill="#FF004F"
                fillOpacity="0.8"
            />
            <path
                d="M8.56723 5.82847C9.16758 6.25901 9.90275 6.51234 10.6968 6.51234V5.30521C10.2536 5.21046 9.86118 4.978 9.56616 4.65468C9.06116 4.33843 8.69732 3.8167 8.59065 3.20605H7.47742V9.33131C7.4749 10.0454 6.89745 10.6236 6.18535 10.6236C5.76572 10.6236 5.39292 10.4228 5.15682 10.1119C4.73521 9.89833 4.44608 9.45991 4.44608 8.95362C4.44608 8.23756 5.02449 7.65699 5.73812 7.65699C5.87485 7.65699 6.00664 7.67835 6.13025 7.71772V6.49739C4.59774 6.52916 3.36523 7.78582 3.36523 9.33134C3.36523 10.1028 3.67216 10.8023 4.1703 11.3133C4.61969 11.6161 5.16068 11.7927 5.74272 11.7927C7.30266 11.7927 8.56726 10.5236 8.56726 8.95808V5.82847H8.56723Z"
                fill="black"
                fillOpacity="0.8"
            />
            <path
                d="M10.6967 5.30549V4.97909C10.297 4.97969 9.9052 4.86736 9.56609 4.65493C9.86628 4.98475 10.2616 5.21218 10.6967 5.30549ZM8.59057 3.20633C8.5804 3.14797 8.57258 3.08923 8.56716 3.03026V2.83301H7.03009V8.95832C7.02763 9.67231 6.45022 10.2505 5.73805 10.2505C5.52897 10.2505 5.33156 10.2007 5.15674 10.1122C5.39284 10.4231 5.76564 10.6238 6.18527 10.6238C6.89731 10.6238 7.47486 10.0457 7.47734 9.33162V3.20633H8.59057ZM6.13025 6.49767V6.15019C6.00181 6.13257 5.87232 6.12373 5.74268 6.12379C4.1826 6.12376 2.91797 7.39288 2.91797 8.95832C2.91797 9.93977 3.41499 10.8047 4.17026 11.3135C3.67212 10.8025 3.36519 10.1031 3.36519 9.33159C3.36519 7.7861 4.59767 6.52944 6.13025 6.49767Z"
                fill="#00F2EA"
                fillOpacity="0.8"
            />
        </svg>
    );
}
