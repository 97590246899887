import type { SVGProps } from 'react';

export default function Views(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M509.618 415.795c-42.695 0-77.253 38.372-77.253 85.738 0 47.352 34.558 86.22 77.253 86.22 42.667 0 76.716-38.867 76.716-86.22 0-47.352-34.049-85.738-76.716-85.738z m1.9-177.9c-211.548 0-383.265 159.859-383.265 274.587 0 114.727 171.717 273.624 383.266 273.624 212.484 0 384.227-162.522 384.227-273.624 0-111.088-171.743-274.587-384.227-274.587z m-1.446 452.006c-89.7 0-162.134-81.71-162.134-181.903 0-101.157 72.435-182.397 162.134-182.397 90.183 0 163.1 81.241 163.1 182.397 0 100.206-72.917 181.903-163.1 181.903z"
                p-id="7846"
                fill="#f8cdd2"
            />
        </svg>
    );
}
