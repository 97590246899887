import type { SVGProps } from 'react';

export default function InboxIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.08268 7.99967H4.90099C5.47199 7.99967 5.99399 8.32229 6.24935 8.83301C6.50471 9.34373 7.02671 9.66634 7.59771 9.66634H12.401C12.972 9.66634 13.494 9.34373 13.7493 8.83301C14.0047 8.32229 14.5267 7.99967 15.0977 7.99967H17.916M7.47149 1.33301H12.5272C13.4246 1.33301 13.8733 1.33301 14.2694 1.46964C14.6198 1.59048 14.9388 1.78767 15.2036 2.04696C15.5029 2.34017 15.7036 2.74151 16.1049 3.54415L17.9104 7.15509C18.0679 7.47008 18.1466 7.62757 18.2022 7.79263C18.2515 7.93922 18.2871 8.09006 18.3085 8.24323C18.3327 8.4157 18.3327 8.59178 18.3327 8.94395V10.6663C18.3327 12.0665 18.3327 12.7665 18.0602 13.3013C17.8205 13.7717 17.4381 14.1542 16.9677 14.3939C16.4329 14.6663 15.7328 14.6663 14.3327 14.6663H5.66602C4.26588 14.6663 3.56582 14.6663 3.03104 14.3939C2.56063 14.1542 2.17818 13.7717 1.9385 13.3013C1.66602 12.7665 1.66602 12.0665 1.66602 10.6663V8.94395C1.66602 8.59178 1.66602 8.4157 1.69016 8.24323C1.7116 8.09006 1.74721 7.93922 1.79653 7.79263C1.85207 7.62757 1.93081 7.47008 2.08831 7.15509L3.89378 3.54415C4.2951 2.7415 4.49577 2.34017 4.79514 2.04696C5.05987 1.78767 5.37894 1.59048 5.72926 1.46964C6.12539 1.33301 6.57409 1.33301 7.47149 1.33301Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
