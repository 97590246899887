import type { SVGProps } from 'react';

export default function Tiktok(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" {...props}>
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3772 20.9236V19.7942C20.9852 19.7387 20.59 19.7099 20.1941 19.708C15.353 19.708 11.4146 23.6472 11.4146 28.4883C11.4146 31.4579 12.8987 34.0866 15.1627 35.6763C13.6468 34.055 12.804 31.918 12.8052 29.6985C12.8052 24.9264 16.6316 21.035 21.3772 20.9236Z"
                fill="#00F2EA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.584 33.7079C23.7439 33.7079 25.5059 31.9897 25.5861 29.8484L25.5934 10.7328H29.0856C29.0111 10.3337 28.9734 9.9286 28.9729 9.52258H24.2035L24.1955 28.6388C24.116 30.7795 22.3533 32.497 20.194 32.497C19.5457 32.4973 18.9072 32.3393 18.3339 32.0368C18.7041 32.5533 19.192 32.9743 19.7571 33.2649C20.3223 33.5555 20.9485 33.7073 21.584 33.7079ZM35.6092 17.2212V16.1588C34.3258 16.16 33.0699 15.7866 31.9957 15.0845C32.9375 16.169 34.2057 16.9187 35.6099 17.2212"
                fill="#00F2EA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.9953 15.0838C30.9425 13.8791 30.3626 12.3333 30.3633 10.7334H29.0854C29.2509 11.6177 29.594 12.4592 30.0942 13.207C30.5944 13.9548 31.2412 14.5932 31.9953 15.0838ZM20.1938 24.478C19.1308 24.4793 18.1117 24.9021 17.36 25.6538C16.6084 26.4054 16.1856 27.4245 16.1843 28.4875C16.1851 29.2187 16.3856 29.9359 16.7643 30.5614C17.143 31.1869 17.6854 31.6971 18.333 32.0367C17.8399 31.3568 17.5744 30.5383 17.5743 29.6985C17.5754 28.6353 17.9981 27.6161 18.7498 26.8643C19.5015 26.1125 20.5207 25.6895 21.5838 25.6883C21.9969 25.6883 22.3928 25.7566 22.7668 25.874V21.0044C22.3749 20.9489 21.9796 20.9201 21.5838 20.9182C21.5141 20.9182 21.4458 20.9222 21.3769 20.9235V24.6638C20.9943 24.542 20.5953 24.4794 20.1938 24.478Z"
                fill="#FF004F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.6094 17.2211V20.9282C33.1358 20.9282 30.8445 20.1371 28.9731 18.7942V28.4876C28.9731 33.3286 25.0353 37.2671 20.1942 37.2671C18.3235 37.2671 16.5886 36.6769 15.1628 35.6755C15.9826 36.5595 16.976 37.2647 18.081 37.7468C19.186 38.2289 20.3786 38.4776 21.5842 38.4773C26.4253 38.4773 30.3638 34.5389 30.3638 29.6985V20.0051C32.2971 21.3953 34.6187 22.1419 37 22.1391V17.3684C36.5225 17.3684 36.0583 17.3166 35.6094 17.2205"
                fill="#FF004F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.9731 28.4875V18.7941C30.9063 20.1844 33.2281 20.9311 35.6093 20.9282V17.2211C34.2053 16.9183 32.9374 16.1684 31.9958 15.0838C31.2416 14.5932 30.5949 13.9548 30.0947 13.207C29.5945 12.4592 29.2513 11.6177 29.0858 10.7334H25.5936L25.5863 29.849C25.5061 31.9896 23.7441 33.7079 21.5842 33.7079C20.9487 33.7073 20.3225 33.5553 19.7573 33.2647C19.1922 32.9742 18.7043 32.5532 18.3341 32.0367C17.6864 31.6972 17.1438 31.1871 16.765 30.5616C16.3861 29.936 16.1855 29.2188 16.1848 28.4875C16.186 27.4245 16.6088 26.4054 17.3605 25.6538C18.1121 24.9021 19.1312 24.4793 20.1942 24.478C20.6067 24.478 21.0026 24.5457 21.3773 24.6638V20.9235C16.6317 21.0349 12.8053 24.9263 12.8053 29.6985C12.8053 32.0062 13.7019 34.1071 15.1628 35.6761C16.6356 36.7129 18.3931 37.2686 20.1942 37.2671C25.0353 37.2671 28.9731 33.3285 28.9731 28.4875Z"
                fill="white"
            />
        </svg>
    );
}
