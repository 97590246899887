import type { SVGProps } from 'react';

export default function Enter(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            version="1.1"
            viewBox="0 0 1200 1200"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="h-6 w-6"
            {...props}
        >
            <path
                d="m918.27 386.79c-4.0039 0.066406-7.8203 1.7188-10.605 4.5938-2.7891 2.875-4.3203 6.7422-4.2617 10.746l0.28516 235.75h-585.49l122.14-118.88h-0.003906c2.8945-2.793 4.5586-6.6211 4.6211-10.645 0.066406-4.0195-1.4766-7.8984-4.2812-10.781-2.8047-2.8828-6.6406-4.5273-10.664-4.5703-4.0195-0.046875-7.8906 1.5117-10.762 4.332l-148.8 144.82c-2.9219 2.8438-4.5664 6.7461-4.5703 10.824 0 4.0742 1.6445 7.9805 4.5625 10.824l148.8 145.12c3.8672 3.7695 9.4531 5.1914 14.648 3.7266 5.1992-1.4648 9.2227-5.5898 10.555-10.824 1.3281-5.2344-0.23047-10.781-4.0977-14.551l-122.2-119.18h598.17-0.003906c4.7617 0.79688 9.6211-0.73047 13.07-4.1094 0.15234-0.14062 0.30469-0.28906 0.45312-0.4375 3.3555-3.4375 4.8789-8.2695 4.0977-13.012l-0.29297-248.45v0.003906c0.054688-4.0859-1.5508-8.0195-4.4453-10.902s-6.832-4.4727-10.918-4.4062z"
                fillRule="evenodd"
            />
        </svg>
    );
}
