import type { SVGProps } from 'react';

const Wechat = ({ ...props }: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
            <path
                fill="#8BC34A"
                d="M18 6C9.2 6 2 12 2 19.5c0 4.3 2.3 8 6 10.5l-2 6 6.3-3.9c1.7.6 3.7.9 5.7.9 8.8 0 16-6 16-13.5S26.8 6 18 6z"
            />
            <path
                fill="#7CB342"
                d="M20 29c0-6.1 5.8-11 13-11h.9c-.1-.7-.3-1.4-.5-2H33c-8.3 0-15 5.8-15 13 0 1.4.3 2.7.7 4 .7 0 1.4-.1 2.1-.2-.5-1.2-.8-2.5-.8-3.8z"
            />
            <path
                fill="#CFD8DC"
                d="M46 29c0-6.1-5.8-11-13-11s-13 4.9-13 11 5.8 11 13 11c1.8 0 3.5-.3 5-.8l5 2.8-1.4-4.8c2.7-2 4.4-4.9 4.4-8.2z"
            />
            <path
                fill="#33691E"
                d="M14 15c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm10-2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            />
            <path
                fill="#546E7A"
                d="M30 26.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5zm7.5-1.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5z"
            />
        </svg>
    );
};
export default Wechat;
