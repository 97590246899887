import type { SVGProps } from 'react';

export default function NicheInfluencer(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M10.9348 12.3702L15.0003 18.3329M9.06586 12.3702L5.00034 18.3329M11.667 10.9997C11.667 11.9201 10.9208 12.6663 10.0003 12.6663C9.07987 12.6663 8.33367 11.9201 8.33367 10.9997C8.33367 10.0792 9.07987 9.33299 10.0003 9.33299C10.9208 9.33299 11.667 10.0792 11.667 10.9997ZM14.2908 3.77655L4.47052 6.40787C4.24511 6.46827 4.13241 6.49847 4.05807 6.5654C3.99268 6.62428 3.94761 6.70235 3.92931 6.78842C3.90852 6.88627 3.93872 6.99897 3.99911 7.22437L4.73243 9.96116C4.79283 10.1866 4.82303 10.2993 4.88996 10.3736C4.94884 10.439 5.02691 10.4841 5.11298 10.5024C5.21082 10.5232 5.32353 10.493 5.54893 10.4326L15.3692 7.80124L14.2908 3.77655ZM18.1607 7.91599C17.259 8.15758 16.8082 8.27837 16.4169 8.19518C16.0726 8.122 15.7603 7.94171 15.5248 7.68015C15.2571 7.3828 15.1363 6.93199 14.8947 6.03037L14.7653 5.54741C14.5237 4.6458 14.4029 4.19499 14.4861 3.8036C14.5593 3.45933 14.7395 3.14706 15.0011 2.91155C15.2985 2.64381 15.7493 2.52301 16.6509 2.28143C16.8763 2.22103 16.989 2.19083 17.0868 2.21163C17.1729 2.22992 17.251 2.27499 17.3098 2.34039C17.3768 2.41472 17.407 2.52743 17.4674 2.75283L18.6321 7.0995C18.6925 7.3249 18.7227 7.4376 18.7019 7.53545C18.6836 7.62152 18.6385 7.69958 18.5731 7.75846C18.4988 7.8254 18.3861 7.8556 18.1607 7.91599ZM2.91843 10.2747L4.04535 9.97272C4.27075 9.91232 4.38345 9.88212 4.45779 9.81519C4.52318 9.75631 4.56825 9.67824 4.58655 9.59218C4.60735 9.49433 4.57715 9.38163 4.51675 9.15622L4.21479 8.02931C4.1544 7.80391 4.1242 7.6912 4.05726 7.61686C3.99839 7.55147 3.92032 7.5064 3.83425 7.48811C3.7364 7.46731 3.6237 7.49751 3.3983 7.5579L2.27138 7.85986C2.04598 7.92026 1.93328 7.95046 1.85894 8.01739C1.79355 8.07627 1.74848 8.15434 1.73018 8.2404C1.70938 8.33825 1.73958 8.45095 1.79998 8.67636L2.10194 9.80327C2.16233 10.0287 2.19253 10.1414 2.25947 10.2157C2.31834 10.2811 2.39641 10.3262 2.48248 10.3445C2.58033 10.3653 2.69303 10.3351 2.91843 10.2747Z"
                stroke="#7C3AED"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
