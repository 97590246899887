export default function Crm({ ...props }) {
    return (
        <svg
            t="1656573780797"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3926"
            {...props}
        >
            <path
                d="M256 554.666667a170.666667 170.666667 0 1 1 0 341.333333 170.666667 170.666667 0 0 1 0-341.333333z m512 0a170.666667 170.666667 0 1 1 0 341.333333 170.666667 170.666667 0 0 1 0-341.333333z m-185.173333 269.184a32 32 0 0 1 0 64l3.541333-0.256 0.085333 0.256a342.528 342.528 0 0 1-148.565333 0.085333l0.085333-0.426667a32 32 0 1 1 15.616-61.738666l-1.749333-0.298667a278.314667 278.314667 0 0 0 121.216-0.213333 35.413333 35.413333 0 0 1 9.728-1.408zM256 618.666667a106.666667 106.666667 0 1 0 0 213.333333 106.666667 106.666667 0 0 0 0-213.333333z m512 0a106.666667 106.666667 0 1 0 0 213.333333 106.666667 106.666667 0 0 0 0-213.333333zM293.12 303.786667A32 32 0 0 1 315.733333 358.4l0.170667 0.170667c-38.4 38.4-65.578667 87.936-76.245333 143.402666a32.298667 32.298667 0 0 1-31.914667 27.904 32 32 0 0 1-30.72-40.917333l-0.213333 0.853333a340.48 340.48 0 0 1 90.752-173.397333 32.128 32.128 0 0 1 25.514666-12.586667z m435.2-2.133334c10.069333 0 18.986667 4.608 24.874667 11.818667l0.170666-0.170667a340.821333 340.821333 0 0 1 90.88 162.688c-0.170667 1.664 0 3.285333 0 4.949334a32 32 0 0 1-63.701333 4.693333l1.408 5.205333a276.906667 276.906667 0 0 0-73.813333-132.266666 32.426667 32.426667 0 0 1-11.776-24.917334 32 32 0 0 1 32-32zM512 85.333333a170.666667 170.666667 0 1 1 0 341.333334 170.666667 170.666667 0 0 1 0-341.333334z m0 64a106.666667 106.666667 0 1 0 0 213.333334 106.666667 106.666667 0 0 0 0-213.333334z"
                p-id="3927"
            />
        </svg>
    );
}
