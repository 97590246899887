import type { SVGProps } from 'react';

export default function Twitter(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M512 1024c282.76736 0 512-229.23264 512-512S794.76736 0 512 0 0 229.23264 0 512s229.23264 512 512 512z m-150.50752-643.13344c98.44736 49.70496 181.63712 46.83776 181.63712 46.83776s-31.5392-109.91616 65.9456-158.65856 164.57728 33.44384 164.57728 33.44384 16.9984-4.66944 29.65504-9.33888c12.67712-4.66944 31.00672-13.0048 31.00672-13.0048l-30.04416 53.61664 46.26432-4.9152s-5.69344 8.35584-24.20736 25.33376c-18.51392 16.97792-26.15296 25.74336-26.15296 25.74336s6.61504 132.3008-63.0784 234.14784c-69.69344 101.86752-159.744 163.00032-290.54976 175.86176-130.80576 12.86144-215.98208-40.1408-215.98208-40.1408s57.20064-3.2768 93.65504-17.2032c36.4544-13.9264 88.8832-50.64704 88.8832-50.64704s-74.5472-22.9376-101.31456-48.7424c-26.74688-25.8048-33.44384-41.10336-33.44384-41.10336l73.58464-0.96256s-77.4144-41.08288-99.38944-73.58464c-21.97504-32.50176-24.84224-64.04096-24.84224-64.04096l56.56576 22.85568s-47.02208-63.93856-53.69856-113.64352c-6.69696-49.70496 8.6016-76.45184 8.6016-76.45184s23.87968 44.89216 122.32704 94.59712z"
                fill="#00acee"
            />
        </svg>
    );
}
