import type { SVGProps } from 'react';

export default function ThumbUpOutline(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5.38257 12.25C6.18892 12.25 6.91648 11.8042 7.4141 11.1697C8.18726 10.1838 9.16169 9.3634 10.2755 8.77021C10.9977 8.38563 11.6243 7.81428 11.9281 7.05464C12.1408 6.5231 12.25 5.95587 12.25 5.38338V4.75C12.25 4.33579 12.5858 4 13 4C14.2426 4 15.25 5.00736 15.25 6.25C15.25 7.40163 14.9904 8.49263 14.5266 9.46771C14.261 10.026 14.6336 10.75 15.2519 10.75M15.2519 10.75H18.3777C19.4044 10.75 20.3233 11.444 20.432 12.4649C20.4769 12.8871 20.5 13.3158 20.5 13.75C20.5 16.5976 19.5081 19.2136 17.851 21.2712C17.4634 21.7525 16.8642 22 16.2462 22H12.2302C11.7466 22 11.2661 21.922 10.8072 21.7691L7.69278 20.7309C7.23393 20.578 6.75342 20.5 6.26975 20.5H4.65421M15.2519 10.75H13M4.65421 20.5C4.73702 20.7046 4.82713 20.9054 4.92423 21.1022C5.12137 21.5017 4.8462 22 4.40067 22H3.49289C2.60418 22 1.77991 21.482 1.52056 20.632C1.18208 19.5226 1 18.3451 1 17.125C1 15.5725 1.29481 14.0889 1.83149 12.7271C2.13655 11.953 2.91733 11.5 3.74936 11.5H4.80212C5.27404 11.5 5.5473 12.0559 5.3023 12.4593C4.47588 13.8198 4 15.4168 4 17.125C4 18.3185 4.23232 19.4578 4.65421 20.5Z"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
