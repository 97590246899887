import type { SVGProps } from 'react';

export default function Link(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 496.158 496.158"
            enableBackground="new 0 0 496.158 496.158"
            xmlSpace="preserve"
            {...props}
        >
            <path
                fill="#25B7D3"
                d="M0,248.085C0,111.064,111.07,0.003,248.076,0.003c137.012,0,248.082,111.061,248.082,248.082
	c0,137.002-111.07,248.07-248.082,248.07C111.07,496.155,0,385.087,0,248.085z"
            />
            <g>
                <path
                    fill="#FFFFFF"
                    d="M394.463,151.309l-49.615-49.614c-10.727-10.728-28.119-10.726-38.844,0l-76.631,76.63
		c-10.726,10.728-10.727,28.119-0.001,38.847l49.615,49.614c10.727,10.727,28.119,10.726,38.845-0.002l76.631-76.63
		C405.188,179.429,405.189,162.036,394.463,151.309z M312.59,235.423c-6.289,6.288-16.484,6.289-22.772,0.001l-29.084-29.084
		c-6.288-6.288-6.287-16.483,0.001-22.772l50.511-50.511c6.287-6.287,16.482-6.288,22.771,0l29.084,29.085
		c6.288,6.287,6.287,16.482,0,22.77L312.59,235.423z"
                />
                <path
                    fill="#FFFFFF"
                    d="M266.786,278.986l-49.614-49.614c-10.727-10.727-28.119-10.726-38.845,0l-76.631,76.632
		c-10.726,10.726-10.727,28.118,0,38.844l49.615,49.615c10.726,10.727,28.119,10.725,38.844,0l76.632-76.633
		C277.511,307.105,277.513,289.713,266.786,278.986z M184.912,363.1c-6.288,6.288-16.482,6.29-22.771,0.001l-29.084-29.084
		c-6.289-6.288-6.288-16.483,0-22.771l50.512-50.512c6.287-6.287,16.482-6.288,22.771,0l29.084,29.084
		c6.288,6.289,6.287,16.484,0,22.771L184.912,363.1z"
                />
            </g>
            <path
                fill="#48A1AF"
                d="M306.907,191.673l-2.42-2.421c-7.742-7.743-20.34-7.743-28.083,0l-87.151,87.151
	c-7.742,7.742-7.742,20.34,0,28.082l2.42,2.421c7.742,7.741,20.34,7.741,28.083,0l87.151-87.152
	C314.649,212.013,314.649,199.414,306.907,191.673z"
            />
            <path
                fill="#FFFFFF"
                d="M215.398,302.548c-5.348,5.348-14.02,5.349-19.368,0.001l-2.421-2.421
	c-5.348-5.348-5.348-14.02,0-19.367l87.152-87.152c5.348-5.349,14.019-5.348,19.368,0.002l2.42,2.42
	c5.347,5.348,5.349,14.019,0,19.366L215.398,302.548z"
            />
        </svg>
    );
}
