import type { SVGProps } from 'react';

export default function ThunderSearch(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.4492 4.68909H6.8C5.11984 4.68909 4.27976 4.68909 3.63803 5.01607C3.07354 5.30369 2.6146 5.76263 2.32698 6.32712C2 6.96886 2 7.80893 2 9.48909V15.6891C2 16.6191 2 17.084 2.10222 17.4655C2.37962 18.5008 3.18827 19.3095 4.22354 19.5869C4.60504 19.6891 5.07003 19.6891 6 19.6891V22.0246C6 22.5574 6 22.8239 6.10923 22.9607C6.20422 23.0797 6.34827 23.1489 6.50054 23.1488C6.67563 23.1486 6.88367 22.9822 7.29976 22.6493L9.68521 20.7409C10.1725 20.3511 10.4162 20.1562 10.6875 20.0175C10.9282 19.8946 11.1844 19.8047 11.4492 19.7503C11.7477 19.6891 12.0597 19.6891 12.6837 19.6891H15.2C16.8802 19.6891 17.7202 19.6891 18.362 19.3621C18.9265 19.0745 19.3854 18.6156 19.673 18.0511C20 17.4093 20 16.5692 20 14.8891V12.6891M17.4999 1.5L13.8564 6.68909C13.6162 7.03118 13.4961 7.20222 13.5 7.34513C13.5034 7.46952 13.5598 7.58582 13.6539 7.66222C13.7619 7.75 13.965 7.75 14.3712 7.75H17.4999V11.5L21.1434 6.31091C21.3836 5.96882 21.5037 5.79778 21.4998 5.65487C21.4964 5.53048 21.44 5.41418 21.346 5.33777C21.2379 5.25 21.0348 5.25 20.6287 5.25H17.4999V1.5Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
